@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: Montserrat;
  background-color: #fff;
}

* {
  box-sizing: border-box;
  font-family: Montserrat;
}

:root {
  --white: #fff;

  --gray-primary: #f7f7f7;
  --gray-secondary: #f4f5fa;

  --black-primary: #211b3e;
  --almost-black: #090c0b;

  --primary-shadow: 0 0 0 4px rgba(255, 133, 10, 0.2);

  --red-primary: #e96062;
  --red-secondary: #ffddde;

  --orange-primary: #ff710a;
  --brownish-grey: #656565;
  --brown-grey: #a1a1a1;

  --very-light-pink: #c7c7c7;
  --very-light-pink-two: #f1f1f1;

  --emerald: #02a758;
  --blue-grey: #8288a2;
  --shamrock: #00ab4d;
  --very-light-gray: #e4e4e4;

  /* Background palette */
  --rhino: #2c3556;
  /* Lynch */
  --tertiary-text: #64728c;
  --manatee: #929baa;
  --mischka: #cfd3de;
  --loblolly: #babfcb;
  --brilliance: #fcfcfd;
  --athens-gray: #e8eaef;
  /* Wash me */
  --input-disabled: #f7f8fa;
  --wash-me: #f7f8fa;

  /* Brand color extended */
  --torch-red: #f82533;
  --coral-red: #f93946;
  /* Most Most Most Important branding color */
  --watermelon: #fa5762;
  --brink-pink: #fb747d;
  --geraldine: #fb8890;
  --your-pink: #ffbdc2;
  --pipin: #ffe7e8;
  --chablis: #fff4f5;

  /* Red extended palette */
  --milano-red: #bf2600;
  --red-dirt: #de350b;
  --outrageous-orange: #ff5630;
  --persimmon: #ff7452;
  --salmon: #ff8f73;
  --wax-flower: #ffbdad;
  --fair-pink: #ffebe6;

  /* Yellow extended palette */
  --pizazz: #ff8b00;
  --tree-poppy: #ff991f;
  --web-orange: #ffab00;
  --amber: #ffc400;
  --kournikova: #ffe380;
  --butter-milk: #fff0b3;
  --early-dawn: #fffae6;

  /* Green extended palette */
  /* stylelint-disable color-hex-length */
  --fun-green: #006644;
  --deep-sea: #00875a;
  --ocean-green: #36b37e;
  --another-shamrock: #57d9a3;
  --spray-green: #79f2c0;
  --magic-mint: #abf5d1;
  --scandal: #e3fcef;

  /* Teal extended palette */
  --deep-cerulean: #008da6;
  --pacific-blue: #00a3bf;
  --cerulean: #00b8d9;
  --robins-egg-blue: #00c7e6;
  --spray-teal: #79e2f2;
  --french-pass: #b3f5ff;
  --bubbles: #e6fcff;

  /* Purple extended palette */
  --purple-ink: #9d219d;
  --minsk: #403294;
  --gigas: #5243aa;
  --fuschia-blue: #6554c0;
  --true-v: #8777d9;
  --chetwode-blue: #998dd9;
  --magnolia: #f8f6ff;
  --moon-raker: #c0b6f2;
  --titan-white: #eae6ff;

  /* Blue extended palette */
  --frosted-blueberries: #005ae4;
  --cobalt: #0747a6;
  --science-blue: #0052cc;
  --blue-ribbon: #0065ff;
  --dodger-blue-dark: #2684ff;
  --dodger-blue-light: #4c9aff;
  --malibu: #7cb5ff;
  --anakiwa: #b3d4ff;
  --zircon: #f4f9ff;
  --pattens-blue: #deebff;
  --twilight-blue: #f5feff;
  --metallic-seaweed: #038298;

  /* Violet extended palette */
  --cloud-break: #f8f0ff;
}

::-webkit-scrollbar {
  height: 10px;
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--loblolly);
  background: var(--loblolly);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--white);
}

div:hover::-webkit-scrollbar-corner {
  width: 5px;
}

::-webkit-scrollbar-track-piece {
  background-color: var(--gray-primary);
}

::-webkit-scrollbar-thumb:vertical {
  background-color: var(--loblolly);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--loblolly);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: var(--manatee);
}

.Toastify * {
  font-family: Montserrat;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: translatey(1);
  }
  to {
    opacity: 1;
    transform: translatey(2);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  0% {
    background-color: var(--wash-me);
  }
  100% {
    background-color: rgba(232, 234, 239, 0.7);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.react-tooltip-lite {
  border-radius: 6px;
}

.cursor-pointer {
  cursor: pointer;
}

.pos-a {
  position: absolute;
}

.header-toolbar-container {
  gap: 16px;
}

.pos-r {
  position: relative;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-view-width {
  width: 100vw;
}

.full-view-height {
  height: 100vh;
}

.text-align-center {
  text-align: center;
}

.fit-content-dimensions {
  width: fit-content;
  height: fit-content;
}

.fit-width {
  width: fit-content;
}

.fit-height {
  height: fit-content;
}

.full-view-dimensions {
  height: 100vh;
  width: 100vw;
}

.full-view-max-width {
  max-width: 100%;
}

.full-view-min-width {
  min-width: 100%;
}

.full-view-max-height {
  max-height: 100%;
}

.full-view-min-height {
  min-height: 100%;
}

.full-dimension {
  height: 100%;
  width: 100%;
}

.truncated-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.infinite-loader {
  animation: rotate360 1s linear infinite;
}
