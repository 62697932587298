.TextInputWithLabel-wrapper {
  width: 100%;

  label {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: var(--rhino);
  }

  input {
    width: 100%;
    height: 48px;
    margin-top: 8px;
    padding: 8px 16px;
    border: solid 1px var(--loblolly);
    border-radius: 4px;
    background-color: var(--white);

    &:disabled {
      border: solid 1px var(--loblolly);
      background-color: var(--wash-me);
      cursor: not-allowed;
    }

    &:focus {
      border: solid 1px var(--watermelon);
      outline: none;
    }

    &.bubbles {
      &:focus {
        border: solid 1px var(--pacific-blue);
        box-shadow: 0px 0px 0px 2px var(--bubbles);
      }
    }
  }

  .warning-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: var(--outrageous-orange);
    margin-top: 8px !important;
  }
}
